/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootReducer} from '../redux/reducers/RootReducer'
import Pagination from '../components/Padination'
import ReqData from '../components/ReqData'
import {useNavigate} from 'react-router-dom'
import {
    IS_VALID_TOKEN,
    NEW_SERVER_URL,
    sendBuySlackMessage,
    SERVER_URL
} from '../components/Common'
import {initToken} from '../redux/reducers/AuthReducer'
import Header from '../components/Header'
import Footer from '../components/Footer'
import {buyMsg, YesNoPopUp} from '../components/popup/PopUp'
import {DeliveryStatusCard} from '../components/DeliveryStatusCard'
import axios from 'axios'

const Text = styled.p<{ $size?: string; $weight?: number; $color?: string }>`
  font-family: 'Pretendard';
  font-size: ${(props) => (props.$size ? props.$size : '20px')};
  font-style: normal;
  font-weight: ${(props) => (props.$weight ? props.$weight : 400)};
  line-height: 26px;
  color: ${(props) => (props.$color ? props.$color : '#191919')};
`
const Button = styled.button<{
    $width?: string
    $height?: string
    $color?: string
    $flex?: number
}>`
  width: ${(props) => (props.$width ? props.$width : '0px')};
  height: ${(props) => (props.$height ? props.$height : '0px')};
  font-family: 'Pretendard';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  background: ${(props) => (props.$color ? props.$color : 'white')};
  color: white;
  border: none;
  border-radius: 10px;
  flex: ${(props) => (props.$flex ? props.$flex : 'none')};
`

const ReturnList = () => {
    const navigate = useNavigate()
    const [validToken, setValidToken] = useState(false)
    const dispatch = useDispatch()
    const token = useSelector((state: RootReducer) => state.Auth.token)
    const infos = JSON.parse(token)
    const [show, setShow] = useState(false)
    const [errMsg] = useState(buyMsg)
    const [ticketCount, setTicketCount] = useState(0)

    useEffect(() => {
        const infos = JSON.parse(token)
        console.log(infos)
        if (!IS_VALID_TOKEN(infos)) navigate('/login')
        else {
            setValidToken(true)
            changedPage(1)

            const params = {
                // 필요한 query params를 {} 형태에 담아준다.
                id: infos.data.idx
            }

            fetch(`${SERVER_URL()}/user/info`, {
                method: 'POST', //메소드 지정
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    Authorization: `Bearer ${infos.data.auth_token}`
                },
                body: JSON.stringify(params)
            })
                .then((res) => res.json()) // 리턴값이 있으면 리턴값에 맞는 req 지정
                .then((res) => {
                    if (res.code == 0) {
                        setTicketCount(res.data.ticket_count)
                    }
                })
        }
    }, [])

    const [totalCnt, setTotalCnt] = useState(0)
    const [datas, setDatas] = useState([])

    const [page, setPage] = useState(1)
    const [dynamicHeight, setDynamicHeight] = useState(1200)
    const [items, setItems] = useState([
        {id: 0, quantity: false},
        {id: 1, quantity: false},
        {id: 2, quantity: false},
        {id: 3, quantity: false},
        {id: 4, quantity: false},
        {id: 5, quantity: false}
    ])

    const calculateDynamicHeight = (count: number): number => {
        const baseHeight = 1200
        const additionalHeightPerGroup = 320
        const groupSize = 3

        // Calculate the number of groups and add height incrementally
        const extraHeight = Math.ceil(count / groupSize - 1) * additionalHeightPerGroup
        return baseHeight + Math.max(0, extraHeight)
    };

    const handleClicked = (order: number, index: number, isExpaned: boolean) => {
        const copiedItems = [...items]
        copiedItems[order * 2 + index].quantity = isExpaned
        setItems(copiedItems)
        let odd = false
        let even = false
        items.map((it, index) => {
            if (index % 2 == 0) {
                if (it.quantity) even = true
            } else {
                if (it.quantity) odd = true
            }
        })

        if (even && odd) setDynamicHeight(1800)
        else if (even || odd) setDynamicHeight(1500)
        else setDynamicHeight(1200)
        //console.log(items)
    }

    const clickEvent = (value: boolean) => {
        setShow(false)
        if (value) sendBuySlackMessage(infos.data.company_name, infos.data.user_tel)
    }

    function changedPage(curPage) {
        setPage(curPage)
        const params = {idx: infos.data.idx, pageNum: curPage}

        fetch(`${SERVER_URL()}/lists`, {
            method: 'POST', //메소드 지정
            headers: {
                //데이터 타입 지정
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${infos.data.auth_token}`
            },
            body: JSON.stringify(params)
        })
            .then((res) => res.json()) // 리턴값이 있으면 리턴값에 맞는 req 지정
            .then((res) => {
                if (res.code == 0) {
                    //성공
                    setTotalCnt(res.total_count)
                    setDatas(res.data)
                } else {
                    setTotalCnt(0)
                    dispatch(initToken())
                    navigate('/')
                }
            })
    }

    const [requestAgencyList, setRequestAgencyList] = useState([])

    useEffect(() => {
        axios
            .get(`${NEW_SERVER_URL()}/request-agency/${infos.data.idx}`)
            .then((res) => {
                console.log(res.data)
                setRequestAgencyList(res.data)
            })
    }, [])

    useEffect(() => {
        // Update dynamicHeight whenever the item count changes
        setDynamicHeight(calculateDynamicHeight(requestAgencyList.length))
    }, [requestAgencyList])
    return (
        <>
            <Header isLong={true}/>
            <div
                onClick={() => {
                }}
                style={{
                    width: '100%',
                    height: `${dynamicHeight}px`,
                    background: 'white',
                    marginTop: '0px',
                    marginBottom: '0px',
                    top: '60px',
                    right: '0px',
                    margin: 'auto'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <div
                        onClick={() => {
                        }}
                        style={{
                            float: 'left',
                            width: '100%',
                            height: '180px',
                            background: '#0862A9'
                        }}
                    />

                    <div
                        style={{
                            float: 'left',
                            width: '100%',
                            height: '70%',
                            background: '#FFFFFF'
                        }}
                    />
                </div>

                <div
                    style={{
                        position: 'absolute',
                        flexDirection: 'column',
                        width: '1320px',
                        minWidth: '438px',
                        //height: 'calc(100vh - 260px - 140px - 103px + 80px)',
                        top: '140px',
                        alignItems: 'center',
                        borderRadius: '20px',
                        left: '50%',
                        transform: 'translate(-50%)'
                    }}
                >
                    <div
                        style={{
                            width: '1280px',
                            height: '160px',
                            marginLeft: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                width: '420px',
                                height: '100%',
                                borderRadius: '20px',
                                background: 'white',
                                boxShadow: '0px 2px 18px 0px rgba(0,0,0,0.3)',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginLeft: '30px'
                                }}
                            >
                                <img
                                    width={'48px'}
                                    height={'48px'}
                                    src={require('../assets/img/car-icon.png')}
                                    alt='BigCo Inc. logo'
                                    style={{marginRight: '10px'}}
                                />

                                <div style={{marginLeft: '18px'}}>
                                    <Text $size={'20px'} $weight={500} style={{margin: '0'}}>
                                        {validToken
                                            ? infos.data != undefined
                                                ? infos.data.user_name
                                                : ''
                                            : ''}
                                    </Text>
                                    <Text $size={'16px'} $weight={400} style={{margin: '0'}}>
                                        {validToken
                                            ? infos.data != undefined
                                                ? infos.data.company_name
                                                : ''
                                            : ''}
                                    </Text>
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                height: '100%',
                                background: 'white',
                                marginLeft: '30px',
                                borderRadius: '20px',
                                boxShadow: '0px 2px 18px 0px rgba(0,0,0,0.3)'
                            }}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Text
                                    $size={'20px'}
                                    $weight={500}
                                    style={{flex: 1, marginLeft: '50px'}}
                                >
                                    잔여 이용권
                                </Text>
                                <Text $size={'20px'} $weight={400} style={{flex: 1}}>
                                    {validToken ? ticketCount : ''} 개
                                </Text>

                                <Button
                                    $width={'160px'}
                                    $height={'60px'}
                                    $color={'#22B4E2'}
                                    onClick={() => {
                                        setShow(true)
                                    }}
                                >
                                    이용권 구매
                                </Button>

                                <div
                                    style={{
                                        height: '100px',
                                        width: '1px',
                                        marginLeft: '30px',
                                        marginRight: '30px',
                                        background: '#E5E5E5'
                                    }}
                                >
                                    <p></p>
                                </div>
                                <Button
                                    onClick={() => navigate('/regist')}
                                    $width={'160px'}
                                    $height={'60px'}
                                    $color={'#0862A9'}
                                    style={{marginRight: '36px', cursor: 'pointer'}}
                                >
                                    신청하기
                                </Button>
                            </div>
                        </div>
                    </div>

                    {/*<div*/}
                    {/*  style={{*/}
                    {/*    width: '1280px',*/}
                    {/*    height: '80px',*/}
                    {/*    marginTop: '30px',*/}
                    {/*    marginLeft: '20px',*/}
                    {/*    background: '#0862A9',*/}
                    {/*    borderRadius: '20px',*/}
                    {/*    display: 'flex',*/}
                    {/*    justifyContent: 'center',*/}
                    {/*    alignItems: 'center',*/}
                    {/*    boxShadow: '0px 2px 18px 0px rgba(0,0,0,0.3)'*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <Text*/}
                    {/*    $size={'24px'}*/}
                    {/*    $weight={800}*/}
                    {/*    $color={'#ffffff'}*/}
                    {/*    style={{ flex: 1, marginLeft: '30px' }}*/}
                    {/*  >*/}
                    {/*    잔여 이용권*/}
                    {/*  </Text>*/}
                    {/*</div>*/}

                    <div
                        style={{
                            overflowY: 'auto',
                            width: '100%',
                            //height: 'calc(100vh - 260px - 140px - 103px - 160px - 60px - 60px)',
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: 24,
                                flexWrap: 'wrap',
                                background: 'transparent',
                                paddingLeft: 24,
                                paddingRight: 24
                            }}
                        >
                            {requestAgencyList.map((item: any, index) => (
                                <DeliveryStatusCard key={item.id || index} item={item}/>
                            ))}
                        </div>
                    </div>
                    {/* <div
            style={{
              width: '100%',
              height: '30px',
              marginTop: '20px'
            }}
          >
            <Pagination
              total={totalCnt}
              limit={5}
              page={page}
              onChanged={changedPage}
            />
          </div> */}
                </div>
            </div>

            <> {show && <YesNoPopUp errMsg={errMsg} clickEvent={clickEvent}/>}</>
            <> <Footer/></>
        </>

    )
}

export default ReturnList
